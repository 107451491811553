<template>
  <v-menu bottom left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn absolute top right icon v-bind="attrs" v-on="on">
        <v-icon>mdi-tray-plus</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, i) in items" :key="i" @click="handleClick(i)">
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import ReportService from '@/services/ReportService';
import { getError } from '@/utils/helpers';

export default {
  name: 'ReportMenuView',
  computed: {
    ...mapGetters('report', ['availableReports']),
    ...mapGetters('subgroup', ['subgroup'])
  },
  props: {
    reportIds: Array
  },
  methods: {
    async handleClick(index) {
      const report = this.items[index];
      if (report && this.subgroup?.id) {
        const filetype = report.properties.allowedFileTypes?.length > 0 ? report.properties.allowedFileTypes[0] : 'pdf';
        await ReportService.getReport({
          report: report.properties.reportName,
          filename: this.filename(
            report.properties.reportFilenamePrefix,
            this.subgroup.homestay_start_date,
            this.subgroup.homestay_end_date,
            filetype
          ),
          subgroup_id: this.subgroup.id
        }).catch(error => {
          store.dispatch('ui/snackbarError', getError(error));
        });
      }
    },
    /**
     * Create the file name for the export
     */
    filename(reportFilenamePrefix, from_date, to_date, extension) {
      let filename = reportFilenamePrefix + ' ' + from_date + ' ' + to_date;
      return filename.replace(/[^a-z0-9]/gi, '-') + '.' + extension;
    }
  },
  watch: {
    availableReports: {
      handler() {
        this.availableReports.forEach(report => {
          if (this.reportIds.find(id => id === report.id)) {
            this.items.push(report);
          }
        });
      },
      immediate: true
    }
  },
  data: () => ({
    items: []
  })
};
</script>
