<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ subgroup.subgroup_name || 'New Subgroup' }}</h1>
    <ReportMenu :reportIds="reportIds" />
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab exact-path :to="'/subgroups/' + subgroup.id">Details</v-tab>
      <v-tab exact-path :to="'/subgroups/' + subgroup.id + '/hosts'" v-if="subgroup.id > 0">Hosts</v-tab>
      <v-tab exact-path :to="'/subgroups/' + subgroup.id + '/paperwork'" v-if="subgroup.id > 0">Paperwork</v-tab>
      <v-tab exact-path :to="'/subgroups/' + subgroup.id + '/itinerary'" v-if="subgroup.id > 0">Itinerary</v-tab>
      <v-tab exact-path :to="'/subgroups/' + subgroup.id + '/namelist'" v-if="subgroup.id > 0">Namelist</v-tab>
      <v-tab exact-path :to="'/subgroups/' + subgroup.id + '/flights'" v-if="subgroup.id > 0">Flights</v-tab>
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="tabs" transition="false">
          <v-card flat>
            <v-card-text>
              <router-view />
            </v-card-text>
          </v-card>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import store from '@/store/index';
import { mapGetters } from 'vuex';
import ReportMenu from './ReportMenu.vue';

export default {
  name: 'SubGroupView',
  beforeRouteEnter(to, from, next) {
    const params = {
      id: to.params.id
    };
    if (to.query.group_id && to.query.group_id > 0) {
      params.group_id = to.query.group_id;
    }
    store.dispatch('subgroup/get', params).then(() => {
      next();
    });
  },
  components: {
    ReportMenu
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('subgroup', ['subgroup']),
    breadcrumbs: function () {
      return [
        {
          text: 'Dashboard',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Groups',
          exact: true,
          to: '/groups'
        },
        {
          text: 'Group',
          disabled: false,
          exact: true,
          to: '/groups/' + this.subgroup.group_id
        },
        {
          text: 'Subgroup',
          disabled: true,
          exact: true,
          to: '/groups/:id'
        }
      ];
    }
  },
  data: () => ({
    tabs: null,
    reportIds: [9, 10, 11, 12, 3, 8]
  })
};
</script>
